import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { cloneDeep } from 'lodash';

import LOADING_STATUS from '../../enums/loadingStatus';
import { PERMISSIONS } from '../../shared/enums';
import { putPermissions } from '../../services/backend/user';
import { UserContext } from '../../contexts/user';
import { MessageContext, MESSAGE_TYPES } from '../../contexts/message';
import useNavigateToLogin from '../../hooks/useNavigateToLogin';
import useStatus from '../../hooks/useStatus';
import Button from '../../components/clickables/button';
import Checkbox from '../../components/clickables/checkbox';

const tag = 'PrivacyContainer';

const PrivacyContainer = () => {
  useNavigateToLogin();
  const { user, setUser, wantsNewsletter, wantsReminder } = useContext(
    UserContext
  );
  const { setMessage, setType } = useContext(MessageContext);
  const { isLoading, setStatus } = useStatus();
  const [newsletter, setNewsletter] = useState(wantsNewsletter);
  const [reminder, setReminder] = useState(wantsReminder);

  useEffect(() => {
    setNewsletter(wantsNewsletter);
  }, [wantsNewsletter]);

  useEffect(() => {
    setReminder(wantsReminder);
  }, [wantsReminder]);

  const handleNewsletterChange = useCallback(() => {
    setNewsletter(!newsletter);
  }, [newsletter]);

  const handleReminderChange = useCallback(() => {
    setReminder(!reminder);
  }, [reminder]);

  const hasChanges = useMemo(
    () => reminder !== wantsReminder || newsletter !== wantsNewsletter,
    [newsletter, reminder, wantsNewsletter, wantsReminder]
  );

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      const optimisticUser = cloneDeep(user);
      optimisticUser.permissions = [];
      if (reminder) {
        optimisticUser.permissions.push(PERMISSIONS.sendReminder);
      }
      if (newsletter) {
        optimisticUser.permissions.push(PERMISSIONS.sendNewsletter);
      }
      setUser(optimisticUser);
      setStatus(LOADING_STATUS.isLoading);
      putPermissions(optimisticUser.permissions)
        .then(() => {
          setStatus(LOADING_STATUS.hasSucceeded);
          setType(MESSAGE_TYPES.success);
          setMessage(
            'Ihre Privatsphäre-Einstellungen wurden erfolgreich übernommen.'
          );
        })
        .catch(error => {
          console.tag(tag).error(error.message);
          setStatus(LOADING_STATUS.hasFailed);
          setType(MESSAGE_TYPES.error);
          setMessage(
            'Es tut uns Leid, da ist etwas schief gegangen, bitte versuche es erneut.'
          );
        });
    },
    [user, reminder, newsletter, setUser, setStatus, setType, setMessage]
  );

  return (
    <form onSubmit={handleSubmit}>
      <h1>Privatsphäre</h1>
      <Checkbox
        id="newsletter-checkbox"
        label="Ich möchte den Psychologie für Sie - Newsletter erhalten."
        onChange={handleNewsletterChange}
        checked={newsletter}
      />
      <Checkbox
        id="reminder-checkbox"
        label="Ich möchte Trainings-Erinnerungen erhalten."
        onChange={handleReminderChange}
        checked={reminder}
      />
      <Button
        label="Speichern"
        isLoading={isLoading}
        disabled={!hasChanges}
        submit
      />
    </form>
  );
};

export default PrivacyContainer;
