import { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';

import useIsLoggedIn from './useIsLoggedIn';
import { isAuthenticated, setOnLoginRediret } from '../utilities/storage';
import { BrowserContext } from '../contexts/browser';

const useNavigateToLogin = () => {
  const isLoggedIn = useIsLoggedIn();
  const { pathname } = useContext(BrowserContext);

  useEffect(() => {
    if (!isLoggedIn && !isAuthenticated()) {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setOnLoginRediret(pathname);
  }, [pathname]);
};

export default useNavigateToLogin;
