import React from 'react';

import { MessageProvider } from '../contexts/message';
import PrivacyContainer from '../containers/Privacy/PrivacyContaner';
import SEO from '../components/layout/seo';

const LoginPage = () => (
  <MessageProvider>
    <SEO title="" />
    <PrivacyContainer />
  </MessageProvider>
);

export default LoginPage;
